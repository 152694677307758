@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    @apply scroll-smooth;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    @apply mb-3;
  }

  h1 {
    @apply text-3xl lg:text-4xl xl:text-5xl;
  }

  h3 {
    @apply text-2xl lg:text-3xl xl:text-4xl;
  }

  a,
  button {
    @apply hover:opacity-80 duration-200;
  }
}

@layer components {
  .form__control {
    @apply mb-6;
  }

  .form__control--flex {
    @apply flex items-start justify-start flex-wrap md:flex-nowrap;
  }

  .form__label {
    @apply w-full max-w-[6.5rem] block font-semibold mr-4 mb-2 md:mb-0;
  }

  .form__input {
    @apply border border-white-2 rounded-[0.25rem] px-3 py-2 bg-white-1 text-black-3 text-sm shadow-1 duration-300 outline-none w-full max-w-sm focus:shadow-2 focus:border-blue-1;
  }
}

.site__root {
  @apply flex flex-col min-h-screen;
}

.site__root > main {
  margin-bottom: auto;
}

.calendar__section {
  @apply flex flex-col-reverse md:flex-row items-start justify-start;
}

.calendar__section > * {
  @apply mb-3;
}

.calendar__section .form__control {
  @apply max-w-[15rem] md:ml-4;
}

.calendar__section .react-datepicker-wrapper {
  @apply hidden;
}

.calendar__section .react-datepicker__triangle {
  display: none !important;
}

.calendar__section .react-datepicker-popper {
  position: static !important;
  transform: none !important;
  padding-top: 0 !important;
}

.book__container {
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  gap: 2rem;
  @apply md:grid;
}

.react-datepicker__input-container > input.calendar__input {
  display: none;
}

button:disabled {
  @apply opacity-50 cursor-not-allowed;
}

img,
svg {
  @apply max-w-full;
}
